<template>
  <div>
    <div>
      <router-link :to="routerBack" class="text-grey-light font-18"
        ><i class="el-icon-arrow-left"></i> ย้อนกลับ</router-link
      >
    </div>
    <h3 class="text-center">{{ edit ? "แก้ไข" : "เพิ่ม" }}ข้อมูลส่วนตัว</h3>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="24" :offset="0">
          <el-form-item label="ระดับ" prop="role">
            <el-select
              v-model="ruleForm.role"
              placeholder="กรุณาเลือกระดับ"
              class="w-100"
            >
              <el-option label="Admin" :value="60"></el-option>
              <el-option label="System Admin" :value="50"></el-option>
              <el-option label="Support" :value="40"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24" :offset="0">
          <el-form-item label="คำนำหน้าชื่อ" prop="titleName">
            <el-select
              v-model="ruleForm.titleName"
              placeholder="กรุณาเลือกคำนำหน้าชื่อ"
              id="titleName"
              class="w-100"
              @change="
                ruleForm.titleName == 4
                  ? (ruleForm.education = 4)
                  : ruleForm.titleName == 1
                  ? (ruleForm.gender = 1)
                  : ruleForm.titleName == 2 || ruleForm.titleName == 3
                  ? (ruleForm.gender = 2)
                  : null
              "
            >
              <el-option
                v-for="data in titleNameOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="24" :offset="0">
          <el-form-item label="ชื่อ" prop="name">
            <el-input v-model="ruleForm.name" id="name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" :offset="0">
          <el-form-item label="นามสกุล" prop="surname">
            <el-input v-model="ruleForm.surname" id="surname"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :md="24" :offset="0">
          <el-form-item label="เพศ" prop="gender">
            <el-radio-group v-model="ruleForm.gender">
              <el-radio
                id="male"
                :label="1"
                :disabled="ruleForm.titleName == 2 || ruleForm.titleName == 3"
                >ชาย</el-radio
              >
              <el-radio id="girl" :label="2" :disabled="ruleForm.titleName == 1"
                >หญิง</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="24" :offset="0">
          <el-form-item label="อายุ" prop="age">
            <el-input
              type="age"
              id="age"
              v-model.number="ruleForm.age"
              v-mask="'##'"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="24" :offset="0">
          <el-form-item label="วุฒิการศึกษา" prop="education" id="education">
            <el-select
              v-model="ruleForm.education"
              placeholder="กรุณาเลือกวุฒิการศึกษา"
              class="w-100"
              @change="
                ruleForm.education == 4 ? (ruleForm.titleName = 4) : null
              "
              :disabled="ruleForm.titleName == 4"
            >
              <el-option
                v-for="data in educationOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="24" :offset="0">
          <el-form-item
            label="เบอร์โทรศัพท์มือถือ"
            prop="mobilephone"
            class="fix-label"
          >
            <el-input
              id="mobilephone"
              v-mask="'##########'"
              v-model="ruleForm.mobilephone"
              maxlength="13"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" :offset="0" v-if="!edit">
          <el-form-item label="อีเมล" prop="email" class="fix-label">
            <el-input v-model="ruleForm.email" type="email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" :offset="0" v-if="!edit">
          <el-form-item label="รหัสผ่าน" prop="password" class="fix-label">
            <el-input
              v-model="ruleForm.password"
              type="password"
              show-password
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :offset="0">
          <el-form-item class="text-center">
            <div class="mg-t-3">
              <div>
                <el-button
                  class="wid180"
                  type="primary"
                  @click="saveEditdata"
                  :loading="loading"
                  >บันทึก</el-button
                >
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  computed: {
    titleNameOption() {
      return this.$store.state.titleName;
    },
    educationOption() {
      return this.$store.state.education;
    }
  },
  data() {
    return {
      loading: false,
      rules: {
        titleName: [
          {
            required: this.validate,
            message: "กรุณาเลือกคำนำหน้า",
            trigger: "change"
          }
        ],
        name: [
          {
            required: this.validate,
            message: "กรุณากรอกชื่อ",
            trigger: "blur"
          }
        ],
        surname: [
          {
            required: this.validate,
            message: "กรุณากรอกนามสกุล",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: this.validate,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
            type: "email"
          }
        ],
        role: [
          {
            required: this.validate,
            message: "กรุณาเลือกระดับ",
            trigger: "change"
          }
        ],
        gender: [
          {
            required: this.validate,
            message: "กรุณาเลือกเพศ",
            trigger: "change"
          }
        ],
        age: [
          {
            required: this.validate,
            message: "กรุณากรอกอายุ",
            trigger: "blur"
          }
        ],
        education: [
          {
            required: this.validate,
            message: "กรุณาเลือกวุฒิการศึกษา",
            trigger: "change"
          }
        ],
        mobilephone: [
          {
            required: this.validate,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือ",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: this.validate,
            message: "กรุณากรอกรหัสผ่าน",
            trigger: "blur"
          }
        ]
      }
    };
  },
  props: {
    routerBack: {
      type: String,
      default: "/"
    },
    ruleForm: {
      type: Object,
      default: {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    saveEditdata() {
      this.loadingBtn = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit("emitData", this.ruleForm);
          setTimeout(() => {
            this.loadingBtn = false;
          }, 300);
        } else {
          setTimeout(() => {
            this.loadingBtn = false;
          }, 300);
          this.$message({
            showClose: true,
            message: "กรุณากรอกข้อมูลให้ครบ",
            type: "error"
          });
          this.confirmReset = false;
        }
      });
    }
  }
};
</script>
